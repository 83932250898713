import { makeAutoObservable, reaction, runInAction } from 'mobx';
import axios from 'axios';
import semverLt from 'semver/functions/lt';
import {
    fetchDefaultRoute,
    fetchDSP,
    fetchMenu,
    fetchPages,
} from '@a2d24/care-at-work-core/Apis/Config'; // For localstorage
import Recycle from '@a2d24/care-at-work-core/Recycle';
export const OCCHEALTH_PREFERRED_ROLE_STORAGE_KEY = 'OCCHEALTH-PreferredRole';

const API_URL = process.env.REACT_APP_API_ENDPOINT;
const APP_VERSION = process.env.REACT_APP_VERSION;

export const renderRoleText = (roleIdentifier) => {
    if (!roleIdentifier) return '';
    return roleIdentifier.toUpperCase().replace(/_/g, ' ');
};

export default class AuthStore {
    occhealthPracticeId = null;
    occhealthPracticeClient = null;
    authenticated = false;
    user = null;
    idToken = null;
    accessToken = null;
    token = null;
    occhealthToken = null;
    appVersion = APP_VERSION;
    shouldUpdateFrontend = false;

    axiosInterceptors = null;
    pages = [];
    menu = [];
    routes = [];

    authorizing = false;
    authorizingErrorMessage = null;
    authorized = false;
    initialized = false;
    displayName = null;
    availableSites = [];
    selectedSite = null;
    availableRoles = [];
    selectedRole = null;
    roleMap = {};
    initialUserProfile = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        reaction(
            () => this.authorized,
            () => {
                if (this.authorized) {
                    this.fetchAppConfig().then((r) => {
                        this.pages = r.pages;
                        this.menu = r.menu;
                        this.routes = r.routes;
                        this.initialized = true;
                        if(this.selectedRole.role !== 'global_omp'){
                            fetchDSP(this.occhealthPracticeId).then((r) => {
                                this.dsp_details = r;
                            });
                        }
                        else{
                            this.dsp_details = {}
                        }
                    });
                    engine.triggerLifecycleEvent('onLogin');
                } else {
                    engine.triggerLifecycleEvent('onLogout');
                }
            }
        );

        reaction(
            () => this.authenticated,
            (authenticated) => {
                if (authenticated) {
                    this.setupAutoLogout();
                    this.updateCredentials();
                }
            }
        );

        reaction(
            () => this.selectedRole,
            (newRole) => {
                Recycle.setHeader('Authorization', `Bearer ${this.occhealthToken}`);
                Recycle.setHeader('Content-Type', 'application/json');
                Recycle.setHeader('X-OcchealthAssumedRole', this.selectedRole.identifier);
                // Update axios interceptors
                if (this.axiosInterceptors) {
                    axios.interceptors.request.eject(this.axiosInterceptors);
                }
                this.axiosInterceptors = axios.interceptors.request.use(
                    (request) => {
                        if (request.url.startsWith(API_URL)) {
                            request.headers['Authorization'] = `Bearer ${this.occhealthToken}`;
                            request.headers['Content-Type'] = 'application/json';
                            request.headers['X-OcchealthAssumedRole'] =
                                this.selectedRole.identifier;
                        }

                        return request;
                    },
                    (error) => {
                        return Promise.reject(error);
                    }
                );
            }
        );

        this.logoutTimer = null;
    }

    setAuthenticated(newAuthenticatedValue) {
        this.authenticated = newAuthenticatedValue;

        if (newAuthenticatedValue === false) {
            this.reset();
        }
    }
    async fetchAppConfig() {
        const configs = await Promise.all([fetchPages(), fetchMenu(), fetchDefaultRoute()]);
        return {
            pages: configs[0],
            menu: configs[1],
            routes: configs[2],
        };
    }

    updateCredentials() {
        const newAccessToken = this.rootStore.mfaStore.getAccessToken();
        this.accessToken = newAccessToken;

        if (newAccessToken) {
            this.onAccessTokenAvailable();
        }
    }

    logout() {
        this.rootStore.mfaStore.logout();
        this.clearAutoLogout();
    }

    reset() {
        this.authenticated = false;
        this.idToken = null;
        this.accessToken = null;
        this.token = null;
        this.occhealthToken = null;
        this.occhealthPracticeId = null;
        this.occhealthPracticeClient = null;
        this.shouldUpdateFrontend = false;

        this.authorizing = false;
        this.authorized = false;
        this.authorizingErrorMessage = null;

        this.initialUserProfile = null;

        // Eject any previous interceptors
        if (this.axiosInterceptors) {
            axios.interceptors.request.eject(this.axiosInterceptors);
            this.axiosInterceptors = null;
        }
    }

    updatePractitionerSummary(newSummary) {
        this.occhealthPractitionerSummary = newSummary;
        this.displayName = this.occhealthPractitionerSummary.display_name;
        this.availableSites = this.occhealthPractitionerSummary.organizations;

        this.roleMap = {};

        for (const site of this.availableSites) {
            for (const role of site.roles) {
                this.roleMap[role.identifier] = { role, site };
            }
        }

        const preferredRole = localStorage.getItem(OCCHEALTH_PREFERRED_ROLE_STORAGE_KEY);
        this.setSelectedRole(preferredRole);
    }

    setSelectedRole = (identifier) => {
        // When a valid identifier is provided, apply it, otherwise default to the first available role
        if (identifier && this.roleMap[identifier]) {
            this.selectedSite = this.roleMap[identifier].site;
            this.selectedRole = this.roleMap[identifier].role;

            this.occhealthPracticeId = this.selectedSite.identifier;

            this.occhealthPracticeClient = this.occhealthPracticeId.split('/')[2];
            this.availableRoles = this.selectedSite.roles;

            localStorage.setItem(
                OCCHEALTH_PREFERRED_ROLE_STORAGE_KEY,
                this.selectedRole.identifier
            );
        } else {
            this.setSelectedSite(this.availableSites.length > 0 ? this.availableSites[0] : null);
        }
    };

    setSelectedSite = (newSite) => {
        this.selectedSite = newSite;
        if (newSite !== null) {
            this.occhealthPracticeId = newSite.identifier;
            this.occhealthPracticeClient = this.occhealthPracticeId.split('/')[2];
            this.availableRoles = newSite.roles;
            if (newSite.roles.length > 0) {
                const selected_role_value = this.selectedRole?.role?.value;
                if (selected_role_value) {
                    const newRole = newSite.roles.find(
                        (siteRole) => siteRole.role.value === selected_role_value
                    );
                    this.selectedRole = newRole ? newRole : newSite.roles[0];
                } else this.selectedRole = newSite.roles[0];
            } else this.selectedRole = null;
            localStorage.setItem(
                OCCHEALTH_PREFERRED_ROLE_STORAGE_KEY,
                this.selectedRole?.identifier
            );
        } else {
            this.occhealthPracticeId = null;
            this.occhealthPracticeClient = null;
            this.availableRoles = [];
            this.selectedRole = null;
        }
    };

    shouldUpdateVersion(frontendLatestVersion = '1.0.0') {
        if (semverLt(this.appVersion, frontendLatestVersion)) this.shouldUpdateFrontend = true;
    }

    async onAccessTokenAvailable() {
        try {
            this.authorizing = true;
            const response = await axios.post(`${API_URL}/Authorization/authorize`, {
                token: this.accessToken,
            });

            runInAction(() => {
                const { occhealth_token, practitioner_summary, frontend_min_version } =
                    response.data;
                this.user = practitioner_summary;
                this.occhealthToken = occhealth_token;
                this.updatePractitionerSummary(practitioner_summary);
                this.shouldUpdateVersion(frontend_min_version);
                this.authorized = true;
            });
        } catch (e) {
            console.log(e);
            runInAction(() => {
                if (e?.response?.status === 401 || e?.response?.status === 403) {
                    this.authorizingErrorMessage = e.response.data;
                } else {
                    console.error(e?.message.status, e?.message.data);
                    this.authorizingErrorMessage =
                        'Unable to authorize your account. If you do have access to the system, please try logging out logging in again.';
                }
            });
        }

        runInAction(() => {
            this.authorizing = false;
        });
    }

    setupAutoLogout() {
        const access_token = this.rootStore.mfaStore.getAccessToken(true);
        if (!access_token) return;

        const autoLogoutInMs = access_token.exp * 1000 - Date.now();

        this.logoutTimer = setTimeout(() => {
            this.logout();
        }, Math.max(0, autoLogoutInMs));

        console.debug(
            `[Auth] Auto Logout has been set [in ${Math.floor(autoLogoutInMs / 1000)} seconds]`
        );
    }

    clearAutoLogout() {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            console.debug(`[Auth] Auto Logout has been cleared`);
        }
        this.logoutTimer = null;
    }
}
